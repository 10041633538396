/*
 * Add color theme colors to the window object
 * so this can be used by the charts and vector maps
 */

const theme = {
  "primary": "#BC9944",
  "secondary": "#6c757d",
  "success": "#1cbb8c",
  "info": "#17a2b8",
  "warning": "#fcb92c",
  "danger": "#dc3545",
  "white": "#fff",
  "gray-100": "#f8f9fa",
  "gray-200": "#e9ecef",
  "gray-300": "#dee2e6",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#343a40",
  "gray-900": "#212529",
  "black": "#000"
};

// Add theme to the window object
window.theme = theme;